import React from "react"
import classNames from "classnames"

import { useFirstMount } from "../../contexts/FirstMountContext"
import Card from "./Card"
import Autoplayer from "./Autoplayer"
import * as styles from "./Works.module.css"

const Works = ({ category, works }) => {
  const isFirstMount = useFirstMount("Works")

  return (
    <Autoplayer>
      <main
        className={classNames(styles.cards, {"appear-bottom": isFirstMount})}
        style={{
          gridTemplateColumns: category.grid.columns
        }}
      >
        {category.cards.map((card) => (
          <div
            key={card.work}
            style={{
              gridColumn: card.grid.column,
              gridRow: card.grid.row
            }}
          >
            <Card work={works[card.work]} />
          </div>
        ))}
      </main>
    </Autoplayer>
  )
}

export default Works
