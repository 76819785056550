import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import Works from "../components/works/Works"

const CategoryPage = ({ data }) => {
  const { category } = data

  const works = []

  for (const work of data.allWork.nodes) {
    works[work.slug] = work
  }

  return (
    <Layout>
      <Seo />
      <Works
        category={category}
        works={works}
      />
    </Layout>
  )
}

export default CategoryPage

export const query = graphql`
  query($name: String) {
    category(name: {eq: $name}) {
      grid {
        columns
      }
      cards {
        work
        grid {
          column
          row
        }
      }
    }
    allWork(filter: {category: {eq: $name}}) {
      nodes {
        slug
        name
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 90,
              aspectRatio: 1.77777777,
              transformOptions: {cropFocus: CENTER}
            )
          }
        }
        video {
          src {
            publicURL
          }
          type
        }
      }
    }
  }
`
